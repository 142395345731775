






import { defineComponent, ref, watch, PropType } from '@vue/composition-api';
import ClassicEditor, { CustomAddStringButtonType, useCkeditor } from '@/composition/ckeditor';

export default defineComponent({
  name: 'FcWysiwyg',
  props: {
    value: {
      type: String,
      require: true,
      default: '',
    },
    // 追加するプラグインタイプ
    addStringButtonTypes: {
      type: Array as PropType<CustomAddStringButtonType[]>,
      default: () => [],
    },
    toolbarItems: {
      type: Array as PropType<string[]>,
      default: () => [],
    },
    removeItems: {
      type: Array as PropType<string[]>,
      default: () => [],
    },
    isDarkMode: {
      type: Boolean,
      require: false,
      default: false,
    },
    enableUploadImages: {
      type: Boolean,
      requires: false,
      default: false,
    },
  },
  setup(props, context) {
    const text = ref(props.value || '');
    const editor = ref(ClassicEditor);
    const ckeditor = useCkeditor(props);
    watch(
      () => text.value,
      () => {
        context.emit('input', text.value);
      }
    );

    // 親側でデータを初期化したときにデータが消えるように
    watch(
      () => props.value,
      () => {
        if (!props.value) text.value = '';
      }
    );
    return {
      editor,
      ckeditor,
      text,
      editorConfig: ckeditor.settings,
    };
  },
});
