import { Ref, ref } from '@vue/composition-api';
import { getSettingWysiwyg, WysiwygProps } from '@/admin/setting';

class WysiwygSetting {
  private _wysiwygProps: Ref<WysiwygProps> = ref({ isDarkMode: false });

  get wysiwygProps() {
    return this._wysiwygProps.value;
  }

  fetchConsoleMenu = async () => {
    const result = await getSettingWysiwyg();
    this._wysiwygProps.value = result.value;
  };
}
export const useWysiwygSetting = () => {
  const wysiwygSetting = new WysiwygSetting();
  return wysiwygSetting;
};
