import core from '@/admin/core';
import {
  DisplayDate,
  removeStorage,
  saveStorage,
  convertWysiwygTextForDisplay,
  convertWysiwygTextForSave,
} from '@/admin/util';
import { computed, ComputedRef, reactive, Ref, ref } from '@vue/composition-api';
import { getSeasons, Season } from './auth';
import { getSubscriptionPlans, SubscriptionPlan } from './payment';
import officialUsers from '@/composition/officialUser';
import myAttributes from '@/composition/myAttributes';

// 通知予約日時
export const notificationTimeOptions = [
  '00:00',
  '00:30',
  '01:00',
  '01:30',
  '02:00',
  '02:30',
  '03:00',
  '03:30',
  '04:00',
  '04:30',
  '05:00',
  '05:30',
  '06:00',
  '06:30',
  '07:00',
  '07:30',
  '08:00',
  '08:30',
  '09:00',
  '09:30',
  '10:00',
  '10:30',
  '11:00',
  '11:30',
  '12:00',
  '12:30',
  '13:00',
  '13:30',
  '14:00',
  '14:30',
  '15:00',
  '15:30',
  '16:00',
  '16:30',
  '17:00',
  '17:30',
  '18:00',
  '18:30',
  '19:00',
  '19:30',
  '20:00',
  '20:30',
  '21:00',
  '21:30',
  '22:00',
  '22:30',
  '23:00',
  '23:30',
];

interface MessagesItem {
  notificationReservationId: string;
  notificationReservationType: 'message';
  notificationReservationStatus: 'draft' | 'unreleased' | 'sending' | 'released' | 'failed';
  createDate: number;
  target: {
    usernames?: string[];
    subscriptionPlanIds?: string[];
    seasonIds?: number[];
    allMembers?: boolean;
  };
  notificationEndDate?: number;
  sendEmail?: boolean;
  releaseDate?: number;
  title: string;
  sendingOfficialUserId?: string;
  body01?: string;
  body02?: string;
  image01?: string;
  image02?: string;
  linkUrl?: string;
  linkText?: string;
  isExternalLink?: boolean;
  isRestricted?: boolean; // 公式ユーザーの権限がある場合に、閲覧権限がないときにtrue
}

// 全件
interface MessagesResponse {
  notificationReservations: MessagesItem[];
  nextToken: string;
}

// 1件
interface MessageResponse extends MessagesItem {
  readUsernames?: string[];
}

// 即時通知
export interface NotificationResponse {
  targetUserCount: number;
  invalidUsernames: string[];
  failedUsernames: string[];
  isFailedEmail: boolean;
}

// 整形
interface MessagesProperty {
  notificationReservationId: string;
  notificationReservationType: 'message';
  notificationReservationStatus: 'draft' | 'unreleased' | 'sending' | 'released' | 'end' | 'failed';
  createDate: DisplayDate;
  target: string;
  notificationEndDate?: DisplayDate;
  sendEmail?: boolean;
  releaseDate?: DisplayDate;
  title: string;
  sendingOfficialUserId: string;
  sendingOfficialUserName: string;
  sendingOfficialUserColor: string;
  body01?: string;
  body02?: string;
  image01?: string;
  image02?: string;
  linkUrl?: string;
  linkText?: string;
  isExternalLink?: boolean;
  isRestricted: boolean;
}

interface MessageProperty {
  notificationReservationId: string;
  notificationReservationType: 'message';
  notificationReservationStatus: 'draft' | 'unreleased' | 'sending' | 'released' | 'failed';
  createDate: DisplayDate;
  notificationEndDate?: DisplayDate;
  sendEmail?: boolean;
  releaseDate?: DisplayDate;
  title: string;
  sendingOfficialUserId: string;
  body01?: string;
  body02?: string;
  image01?: string;
  image02?: string;
  linkUrl?: string;
  linkText?: string;
  isExternalLink?: boolean;
}

// put
interface MessageProps {
  notificationType: 'message';
  notificationReservationType: 'message';
  notificationReservationStatus: 'draft' | 'unreleased';
  target: {
    usernames?: string[];
    subscriptionPlanIds?: string[];
    seasonIds?: number[];
    allMembers?: boolean;
  };
  notificationEndDate?: number;
  sendEmail?: boolean;
  releaseDate?: number;
  title: string;
  sendingOfficialUserId?: string;
  body01?: string;
  body02?: string;
  body01Images?: string[];
  body02Images?: string[];
  image01?: string;
  image02?: string;
  linkUrl?: string;
  linkText?: string;
  isExternalLink?: boolean;
}

interface MessageTarget {
  acceptAllMembers: boolean;
  planIds: string[];
  seasonIds: number[];
  userIds: string[];
}
export interface MessageStorageValue {
  createDate: string;
  id: string;
  item: MessageProperty;
  target: MessageTarget;
}

// 全件取得
const getMessages = async (nextToken?: string) => {
  if (myAttributes.myRequestPermissions?.notificationReservations) {
    try {
      const result = await core.httpClient.get(
        `/admin/public/notificationReservations${nextToken ? `?nextToken=${nextToken}` : ''}`
      );
      return result.data as MessagesResponse;
    } catch (e) {
      return {} as MessagesResponse;
    }
  } else return {} as MessagesResponse;
};

// 1件取得
const getMessage = async (notificationReservationId: string) => {
  if (myAttributes.myRequestPermissions?.notificationReservations) {
    try {
      const result = await core.httpClient.get(`/admin/public/notificationReservations/${notificationReservationId}`);
      return result.data as MessageResponse;
    } catch (e) {
      return null;
    }
  } else return null;
};

export class Messages {
  private _isLoading = computed(() => {
    return officialUsers.fetchLoading || this._isMessageLoading.value;
  });
  private _isMessageLoading = ref(false);
  private _messages: Ref<MessagesItem[]> = ref([]);
  private _plans: Ref<SubscriptionPlan[]> = ref([]);
  private _seasons: Ref<Season[]> = ref([]);
  private _items: ComputedRef<MessagesProperty[]> = computed(() => {
    const now = Date.now();
    // 整形
    return this._messages.value
      .map((message) => {
        let target = '';
        if (message.target.allMembers) {
          target = '無料含む全ての会員';
        }
        if (message.target.usernames) {
          target = 'ユーザー指定';
        } else if (message.target.subscriptionPlanIds) {
          const _planNames = message.target.subscriptionPlanIds.map((subscriptionPlanId) => {
            const subscriptionPlan = this._plans.value.find((plan) => plan.subscriptionPlanId === subscriptionPlanId);
            return subscriptionPlan?.subscriptionPlanName
              ? subscriptionPlan.isArchive
                ? `${subscriptionPlan?.subscriptionPlanName}(削除済み)`
                : subscriptionPlan?.subscriptionPlanName
              : '';
          });
          target = _planNames.join('<br>');
        } else if (message.target.seasonIds) {
          const thisSeason = this._seasons.value.find((season) => season.isActive) || null;
          if (thisSeason) {
            const _seasonsData = this._seasons.value.map((season) => {
              let text = '';
              const value: number[] = [];
              if (thisSeason.seasonId <= season.seasonId) {
                text = season.isActive
                  ? `${season.seasonName}（アクティブシーズン）`
                  : `${season.seasonName}（シーズン予約）`;
                value.push(season.seasonId);
              } else {
                text = `${season.seasonName}（${season.seasonName}~${thisSeason.seasonName}）`;
                for (let i = season.seasonId; i <= thisSeason.seasonId; i++) {
                  value.push(i);
                }
              }
              return {
                text,
                value,
              };
            });
            target =
              _seasonsData.find((item) => item.value.toString() === message.target.seasonIds?.toString())?.text || '';
          }
        }

        const data: MessagesProperty = {
          notificationReservationId: message.notificationReservationId,
          notificationReservationType: message.notificationReservationType || 'message', // TODO 通知予約タイプの移行完了後 || 'message'削除
          notificationReservationStatus:
            message.notificationReservationStatus === 'released' &&
            message.notificationEndDate &&
            message.notificationEndDate < now
              ? 'end'
              : message.notificationReservationStatus,
          createDate: new DisplayDate(message.createDate),
          target: target,
          notificationEndDate: new DisplayDate(message.notificationEndDate),
          sendEmail: !!message.sendEmail,
          releaseDate: new DisplayDate(message.releaseDate),
          title: message.title,
          sendingOfficialUserId: message.sendingOfficialUserId || 'nouser',
          sendingOfficialUserName: message.sendingOfficialUserId
            ? officialUsers.getOfficialUserName(message.sendingOfficialUserId)
            : '表示しない',
          sendingOfficialUserColor: officialUsers.getOfficialUserColor(message.sendingOfficialUserId || ''),
          isRestricted: !!message.isRestricted,
        };
        return data;
      })
      .sort((a, b) => (b.createDate.value && a.createDate.value ? b.createDate.value - a.createDate.value : -1));
  });
  private _nextToken = ref('');

  get isLoading() {
    return this._isLoading.value;
  }
  get nextToken() {
    return this._nextToken.value;
  }
  get items() {
    return this._items.value;
  }

  constructor() {
    this.init();
  }

  init = async () => {
    this._isMessageLoading.value = true;
    await this.getPlans();
    await this.getSeasons();
    await this.getMessages();
    this._isMessageLoading.value = false;
  };

  getPlans = async () => {
    this._plans.value = await getSubscriptionPlans();
  };

  getSeasons = async () => {
    this._seasons.value = await getSeasons();
  };

  getMessages = async () => {
    const messages = await getMessages();
    this._messages.value = messages.notificationReservations;
    this._nextToken.value = messages.nextToken;
  };

  getNextMessages = async (nextToken: string) => {
    const messages = await getMessages(nextToken);
    this._messages.value = [...this._messages.value, ...messages.notificationReservations];
    this._nextToken.value = messages.nextToken;
  };

  // 即時通知
  notifyMessage = async (notificationReservationId: string) => {
    const result = await core.httpClient.post('/admin/public/notificationReservations/notifications', {
      notificationReservationId,
    });
    return result.data as NotificationResponse;
  };

  setSendingStatus(notificationReservationId: string) {
    const index = this._messages.value.findIndex(
      (message) => message.notificationReservationId === notificationReservationId
    );
    if (0 <= index) this._messages.value[index].notificationReservationStatus = 'sending';
  }

  // 複製
  copyMessage = async (notificationReservationId: string) => {
    await core.httpClient.post(`/admin/public/notificationReservations/${notificationReservationId}`);
  };

  // 削除
  deleteMessage = async (notificationReservationId: string) => {
    await core.httpClient.delete(`/admin/public/notificationReservations/${notificationReservationId}`);
  };

  removeStorage = (notificationReservationId: string) => {
    // ローカルストレージにあれば削除
    const storageKey = `notificationId:${notificationReservationId}`;
    removeStorage(storageKey);
  };
}

export class Message {
  isLoading = true;
  isAllowInput = true;
  isLink = false;
  isNotificationEndDate = false;
  target: MessageTarget = {
    acceptAllMembers: false,
    planIds: [],
    seasonIds: [],
    userIds: [],
  };
  targetPlanNames: string[] = [];
  targetSeasonName = '';
  storageKey: string; // ローカルストレージキーを保持する

  item: MessageProperty = {
    notificationReservationId: '',
    notificationReservationType: 'message',
    notificationReservationStatus: 'draft',
    createDate: new DisplayDate(),
    notificationEndDate: new DisplayDate(),
    sendEmail: true,
    releaseDate: new DisplayDate(),
    title: '',
    sendingOfficialUserId: '',
    body01: '{{nickname}} さん',
    body02: '',
    image01: '',
    image02: '',
    linkUrl: '',
    linkText: '',
    isExternalLink: false,
  };
  props: MessageProps | null = null;

  storageValue: MessageStorageValue = reactive({
    createDate: DisplayDate.now().dateTime,
    id: '',
    item: this.item,
    target: this.target,
  });

  constructor(notificationReservationId?: string) {
    if (!notificationReservationId) {
      this.storageKey = 'notificationId:newCreate';
    } else {
      this.item.notificationReservationId = notificationReservationId;
      this.storageKey = `notificationId:${notificationReservationId}`;
      this.storageValue.id = notificationReservationId;
    }
  }

  init = async () => {
    this.isLoading = true;
    await this.getMessage(this.item.notificationReservationId);
    this.isLoading = false;
  };

  setStorageValue = async () => {
    this.item = {
      ...this.storageValue.item,
      body01: await convertWysiwygTextForDisplay(this.storageValue.item.body01),
      body02: await convertWysiwygTextForDisplay(this.storageValue.item.body02),
    };
    this.target = this.storageValue.target;
    this.isLink = !!this.item.linkUrl && !!this.item.linkText;
    this.isNotificationEndDate = !!this.item.notificationEndDate?.value;
    this.isLoading = false;
  };
  transformStorage = (value: MessageStorageValue) => {
    value.item.releaseDate = new DisplayDate(value.item.releaseDate?.value);
    value.item.notificationEndDate = new DisplayDate(value.item.notificationEndDate?.value);
    this.storageValue.createDate = value.createDate;
    this.storageValue.item = value.item;
    this.storageValue.target = value.target;
  };
  setStorage = () => {
    this.storageValue.item = {
      ...this.item,
      body01: convertWysiwygTextForSave(this.item.body01 || '').text,
      body02: convertWysiwygTextForSave(this.item.body02 || '').text,
    };
    this.storageValue.target = this.target;
    saveStorage<'message'>(this.storageKey, this.storageValue);
  };
  removeStorage = () => removeStorage(this.storageKey);

  getMessage = async (notificationReservationId: string) => {
    // getした場合storageの削除
    this.removeStorage();
    if (!notificationReservationId) return;
    const _item = await getMessage(notificationReservationId);
    if (_item === null) {
      alert('データが取得できませんでした。一覧ページに戻ります。');
      location.href = '/#/messages';
      return;
    }

    this.item = {
      notificationReservationId: _item.notificationReservationId,
      notificationReservationType: _item.notificationReservationType || 'message', // TODO 通知予約タイプの移行完了後 || 'message'削除
      notificationReservationStatus: _item.notificationReservationStatus,
      createDate: new DisplayDate(_item.createDate),
      notificationEndDate: new DisplayDate(_item.notificationEndDate) || '',
      sendEmail: _item.sendEmail,
      releaseDate: new DisplayDate(_item.releaseDate) || '',
      title: _item.title,
      sendingOfficialUserId: _item.sendingOfficialUserId || 'nouser',
      body01: await convertWysiwygTextForDisplay(_item.body01),
      body02: await convertWysiwygTextForDisplay(_item.body02),
      image01: _item.image01 || '',
      image02: _item.image02 || '',
      linkUrl: _item.linkUrl || '',
      linkText: _item.linkText || '',
      isExternalLink: _item.isExternalLink,
    };
    // 入力は新規作成時もしくはステータスが下書きの時のみ有効
    this.isAllowInput = !_item.notificationReservationId || _item.notificationReservationStatus === 'draft';
    this.isLink = !!_item.linkUrl && !!_item.linkText;
    this.isNotificationEndDate = !!_item.notificationEndDate;
    this.target.acceptAllMembers = !!_item.target.allMembers;
    if (_item.target.usernames?.length) this.target.userIds = _item.target.usernames;
    if (_item.target.subscriptionPlanIds?.length) {
      this.target.planIds = _item.target.subscriptionPlanIds;
      this.getTargetPlanNames();
    }
    if (_item.target.seasonIds?.length) {
      this.target.seasonIds = _item.target.seasonIds;
      this.getTargetSeasonName();
    }
  };

  createProps = (statusDraft?: boolean) => {
    if (
      this.item.notificationReservationStatus === 'sending' ||
      this.item.notificationReservationStatus === 'released' ||
      this.item.notificationReservationStatus === 'failed'
    )
      return;
    // バリデート
    const now = Date.now();
    if (
      !this.item.title ||
      !this.item.sendingOfficialUserId ||
      !this.item.notificationReservationStatus ||
      (!this.target.acceptAllMembers &&
        !this.target.planIds.length &&
        !this.target.seasonIds.length &&
        !this.target.userIds.length)
    ) {
      throw 'タイトル，送信者，ステータス，通知対象で未入力があります';
    } else if (this.item.title.match(/\{*nickname\}*/g)) {
      throw 'タイトルに nickname は使用できません';
    } else if (this.isLink && (!this.item.linkUrl || !this.item.linkText)) {
      throw 'リンク先を使用する場合はURLとリンクテキストが必須です';
    } else if (
      this.isLink &&
      this.item.linkUrl &&
      !this.item.linkUrl.match(/^(https?|ftp)(:\/\/[-_.!~*'()a-zA-Z0-9;/?:@&=+$,%#]+)$/)
    ) {
      throw 'URLが不正です。ドメインから正しく指定してください';
    } else if (!this.isLink && !this.item.body01 && !this.item.body02) {
      throw 'リンク先を使用しない場合は本文の入力が必須です';
    } else if (
      this.item.notificationReservationStatus === 'unreleased' &&
      this.item.releaseDate &&
      (this.item.releaseDate.value === undefined || !notificationTimeOptions.includes(this.item.releaseDate.time))
    ) {
      throw '通知予約日時を正しく入力してください';
    } else if (
      this.isNotificationEndDate &&
      this.item.notificationEndDate &&
      this.item.notificationEndDate.value === undefined
    ) {
      throw '通知終了日時を入力してください';
    } else if (
      this.item.notificationReservationStatus === 'unreleased' &&
      this.item.releaseDate?.value &&
      this.item.releaseDate.value < now
    ) {
      throw '通知予約日時が現在日時より前に設定されています';
    } else if (
      this.item.notificationReservationStatus === 'unreleased' &&
      this.isNotificationEndDate &&
      this.item.notificationEndDate?.value &&
      this.item.notificationEndDate.value < now
    ) {
      throw '通知終了日時が現在日時より前に設定されています';
    } else if (
      this.item.notificationReservationStatus === 'unreleased' &&
      this.isNotificationEndDate &&
      this.item.notificationEndDate?.value &&
      this.item.releaseDate?.value &&
      this.item.notificationEndDate.value < this.item.releaseDate.value
    ) {
      throw '通知終了日時が通知予約日時より前に設定されています';
    } else {
      this.props = {
        notificationReservationType: 'message',
        notificationType: 'message',
        notificationReservationStatus: statusDraft ? 'draft' : this.item.notificationReservationStatus,
        title: this.item.title,
        target: {},
      };
      if (this.item.sendingOfficialUserId && this.item.sendingOfficialUserId !== 'nouser')
        this.props.sendingOfficialUserId = this.item.sendingOfficialUserId;
      if (this.isNotificationEndDate && this.item.notificationEndDate)
        this.props.notificationEndDate = this.item.notificationEndDate.value;
      if (this.item.sendEmail) this.props.sendEmail = this.item.sendEmail;
      // ステータス下書き：通知日時は削除
      if (this.item.releaseDate && !statusDraft && this.item.notificationReservationStatus === 'unreleased') {
        this.props.releaseDate = this.item.releaseDate.value;
      } else if (this.item.releaseDate) {
        this.item.releaseDate = new DisplayDate();
      }
      if (this.target.userIds.length) this.props.target.usernames = this.target.userIds;
      if (this.target.planIds.length) this.props.target.subscriptionPlanIds = this.target.planIds;
      if (this.target.seasonIds.length) this.props.target.seasonIds = this.target.seasonIds;
      if (this.target.acceptAllMembers) this.props.target.allMembers = true;
      if (this.isLink) {
        this.props.linkUrl = this.item.linkUrl;
        this.props.linkText = this.item.linkText;
        this.props.isExternalLink = this.item.isExternalLink;
        this.item.body01 = '';
        this.item.body02 = '';
        this.item.image01 = '';
        this.item.image02 = '';
      } else {
        if (this.item.body01) {
          const { text, images } = convertWysiwygTextForSave(this.item.body01);
          this.props.body01 = text;
          this.props.body01Images = images;
        }
        if (this.item.body02) {
          const { text, images } = convertWysiwygTextForSave(this.item.body02);
          this.props.body02 = text;
          this.props.body02Images = images;
        }
        if (this.item.image01) this.props.image01 = this.item.image01;
        if (this.item.image02) this.props.image02 = this.item.image02;
        this.item.linkUrl = '';
        this.item.linkText = '';
        this.item.isExternalLink = false;
      }
    }
  };

  createMessage = async () => {
    this.createProps();
    if (!this.props) return;
    await core.httpClient.post('/admin/public/notificationReservations', this.props);
    // 保存した場合sessionの削除
    this.removeStorage();
  };

  saveMessage = async (notificationReservationId: string, statusDraft = false) => {
    this.createProps(statusDraft);
    if (!this.props) return;
    await core.httpClient.put(`/admin/public/notificationReservations/${notificationReservationId}`, this.props);
    // 保存した場合sessionの削除
    this.removeStorage();
  };

  getTargetPlanNames = async () => {
    const _plans = await getSubscriptionPlans();
    this.targetPlanNames = this.target.planIds.map((subscriptionPlanId) => {
      const subscriptionPlan = _plans.find((plan) => plan.subscriptionPlanId === subscriptionPlanId);
      return subscriptionPlan?.subscriptionPlanName
        ? subscriptionPlan.isArchive
          ? `${subscriptionPlan?.subscriptionPlanName}(削除済み)`
          : subscriptionPlan?.subscriptionPlanName
        : '';
    });
  };

  getTargetSeasonName = async () => {
    const _seasons = await getSeasons();
    const _getSeasonData = () => {
      const thisSeason = _seasons.find((season) => season.isActive) || null;
      if (thisSeason) {
        return _seasons.map((season) => {
          let text = '';
          const value: number[] = [];
          if (thisSeason.seasonId <= season.seasonId) {
            text = season.isActive
              ? `${season.seasonName}（アクティブシーズン）`
              : `${season.seasonName}（シーズン予約）`;
            value.push(season.seasonId);
          } else {
            text = `${season.seasonName}（${season.seasonName}~${thisSeason.seasonName}）`;
            for (let i = season.seasonId; i <= thisSeason.seasonId; i++) {
              value.push(i);
            }
          }
          return {
            text,
            value,
          };
        });
      } else return [];
    };
    const _seasonsData = _getSeasonData();

    this.targetSeasonName =
      _seasonsData.find((item) => item.value.toString() === this.target.seasonIds.toString())?.text || '';
  };
}

export const useMessages = () => {
  const messages = new Messages();
  return messages;
};

export const useMessage = (notificationReservationId?: string) => {
  const message = new Message(notificationReservationId);
  return { message: reactive(message) };
};
