



























































































































import { defineComponent, ref, PropType, Ref, watch, reactive, computed } from '@vue/composition-api';
import { usePayment, SubscriptionPlan, SubscriptionProduct } from '@/admin/payment';
import { getProducts, ProductResponse } from '@/admin/product';
import { getGifts, GiftResponse } from '@/admin/gift';
import { getSeasons, Season } from '@/admin/auth';
import FcUserSelect from '@/components/FcUserSelect.vue';
import myAttributes from '@/composition/myAttributes';

interface Permission {
  acceptAllMembers?: boolean;
  acceptAllUsers?: boolean;
  deny?: boolean;
  planIds?: string[];
  seasonIds?: number[];
  productIds?: string[];
  giftIds?: string[];
  userIds?: string[];
}

interface Plan {
  text: string;
  plan: string;
  group: string;
  value: string;
  isArchive: boolean;
}
export default defineComponent({
  name: 'Authority',
  components: {
    FcUserSelect,
  },
  props: {
    value: {
      type: Object as PropType<Permission>,
      require: true,
      default: {
        acceptAllMembers: false,
        acceptAllUsers: false,
        deny: false,
        planIds: [],
        seasonIds: [],
        productIds: [],
        giftIds: [],
        userIds: [],
      },
    },
    isAllUser: {
      type: Boolean,
      default: false,
    },
    isAllMember: {
      type: Boolean,
      default: false,
    },
    isDeny: {
      type: Boolean,
      default: false,
    },
    isPlan: {
      type: Boolean,
      default: false,
    },
    isSeason: {
      type: Boolean,
      default: false,
    },
    isProduct: {
      type: Boolean,
      default: false,
    },
    isGift: {
      type: Boolean,
      default: false,
    },
    isUser: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    isUnrestrictedBySubscriptionPlans: {
      type: Boolean,
      default: true,
    },
  },
  setup(props, context) {
    const { getSubscriptionPlans, getSubscriptionProducts } = usePayment();
    const authority = reactive(props.value);
    const selected = ref('');
    const selectedPlans: Ref<string[]> = ref(authority.planIds || []);
    const selectedSeasons: Ref<number[]> = ref(authority.seasonIds || []);
    const selectedProducts: Ref<string[]> = ref(authority.productIds || []);
    const selectedGifts: Ref<string[]> = ref(authority.giftIds || []);
    const selectedUsers: Ref<string[]> = ref(authority.userIds || []);
    const displayArchiveProduct = ref(false);
    const displayArchiveGift = ref(false);
    const displayArchivePlan = ref(false);
    if (authority.acceptAllUsers) selected.value = 'allUsers';
    else if (authority.acceptAllMembers) selected.value = 'allMembers';
    else if (authority.deny) selected.value = 'deny';
    else if (authority.planIds?.length) selected.value = 'plans';
    else if (authority.seasonIds?.length) selected.value = 'seasons';
    else if (authority.productIds?.length) selected.value = 'products';
    else if (authority.giftIds?.length) selected.value = 'gifts';
    else if (authority.userIds?.length) selected.value = 'users';
    const plans: Ref<Plan[]> = ref([]);
    const displayOptionPlans = computed(() => {
      if (displayArchivePlan.value) return plans.value;
      else return plans.value.filter((plan) => !plan.isArchive);
    });
    const seasons: Ref<{ text: string; value: number[] }[]> = ref([]);
    const products: Ref<{ text: string; value: string; isArchive: boolean }[]> = ref([]);
    const displayOptionProducts = computed(() => {
      if (displayArchiveProduct.value) return products.value;
      else return products.value.filter((product) => !product.isArchive);
    });
    const gifts: Ref<{ text: string; value: string; isArchive: boolean }[]> = ref([]);
    const displayOptionGifts = computed(() => {
      if (displayArchiveGift.value) return gifts.value;
      else return gifts.value.filter((gift) => !gift.isArchive);
    });
    const thisSeason: Ref<Season | null> = ref(null);
    Promise.all([
      props.isPlan ? getSubscriptionPlans() : new Promise<SubscriptionPlan[]>((resolve) => resolve([])),
      props.isPlan ? getSubscriptionProducts() : new Promise<SubscriptionProduct[]>((resolve) => resolve([])),
      props.isSeason ? getSeasons() : new Promise<Season[]>((resolve) => resolve([])),
      props.isProduct ? getProducts() : new Promise<ProductResponse[]>((resolve) => resolve([])),
      props.isGift ? getGifts() : new Promise<GiftResponse[]>((resolve) => resolve([])),
    ]).then(([_plans, _subscriptionProducts, _seasons, _products, _gifts]) => {
      // 商品ID一覧：プランのorder順に基づいた商品IDの配列作成
      const subscriptionProductIds = _plans
        .map((plan) => plan.subscriptionProductId)
        .filter((id, index, productIds) => index === productIds.indexOf(id));
      // 商品ID順にプラン（オーダー順）の一覧
      plans.value = subscriptionProductIds.reduce((acc_plans, val_productId) => {
        // 商品名
        const subscriptionProductName =
          _subscriptionProducts.find((product) => val_productId === product.subscriptionProductId)
            ?.subscriptionProductName || '';
        if (subscriptionProductName) {
          const productPlans = _plans
            .filter((subscriptionPlan) => {
              return !props.isUnrestrictedBySubscriptionPlans
                ? myAttributes.subscriptionPlans.includes(subscriptionPlan.subscriptionPlanId)
                : true;
            })
            .filter((plan) => val_productId === plan.subscriptionProductId)
            .map((plan) => {
              return {
                text: plan.isArchive
                  ? `【削除済み/${subscriptionProductName}】${plan.subscriptionPlanName}`
                  : `【${subscriptionProductName}】${plan.subscriptionPlanName}`,
                group: plan.isArchive ? `削除済み/${subscriptionProductName}` : subscriptionProductName,
                plan: plan.subscriptionPlanName,
                value: plan.subscriptionPlanId,
                isArchive: !!plan.isArchive,
              };
            });
          acc_plans.push(...productPlans);
        }
        return acc_plans;
      }, [] as Plan[]);
      // selectedPlansが削除済みを含む場合、削除済み表示フラグをON
      if (selectedPlans.value.length) {
        displayArchivePlan.value = selectedPlans.value.some(
          (planId) => plans.value.find((plan) => plan.value === planId)?.isArchive
        );
      }
      // 現シーズン
      const thisSeason = _seasons.find((season) => season.isActive) || null;
      if (thisSeason) {
        seasons.value = _seasons.map((season) => {
          let text = '';
          const value: number[] = [];
          if (thisSeason.seasonId <= season.seasonId) {
            text = season.isActive
              ? `${season.seasonName}（アクティブシーズン）`
              : `${season.seasonName}（シーズン予約）`;
            value.push(season.seasonId);
          } else {
            text = `${season.seasonName}（${season.seasonName}~${thisSeason.seasonName}）`;
            for (let i = season.seasonId; i <= thisSeason.seasonId; i++) {
              value.push(i);
            }
          }
          return {
            text,
            value,
          };
        });
      }
      products.value = _products.map((product) => ({
        text: product.isArchive
          ? `【削除済み/${product.productId}】${product.productName}`
          : `【${product.productId}】${product.productName}`,
        value: product.productId,
        isArchive: !!product.isArchive,
      }));
      // selectedProductsが削除済みを含む場合、削除済み表示フラグをON
      if (selectedProducts.value.length) {
        displayArchiveProduct.value = selectedProducts.value.some(
          (item) => products.value.find((product) => product.value === item)?.isArchive
        );
      }
      gifts.value = _gifts.map((gift) => ({
        text: gift.isArchive ? `【削除済み/${gift.giftId}】${gift.giftName}` : `【${gift.giftId}】${gift.giftName}`,
        value: gift.giftId,
        isArchive: !!gift.isArchive,
      }));
      // selectedGiftsが削除済みを含む場合、削除済み表示フラグをON
      if (selectedGifts.value.length) {
        displayArchiveGift.value = selectedGifts.value.some(
          (item) => gifts.value.find((gift) => gift.value === item)?.isArchive
        );
      }
    });

    watch(
      () => selected.value,
      () => {
        if (selected.value === 'allUsers') authority.acceptAllUsers = true;
        else authority.acceptAllUsers = false;
        if (selected.value === 'allMembers') authority.acceptAllMembers = true;
        else authority.acceptAllMembers = false;
        if (selected.value === 'deny') authority.deny = true;
        else authority.deny = false;
        if (selected.value === 'users') authority.userIds = selectedUsers.value;
        else authority.userIds = [];
        if (selected.value === 'plans') {
          authority.planIds = selectedPlans.value;
        } else authority.planIds = [];
        if (selected.value === 'seasons') {
          authority.seasonIds = selectedSeasons.value;
        } else authority.seasonIds = [];
        if (selected.value === 'products') authority.productIds = selectedProducts.value;
        else authority.productIds = [];
        if (selected.value === 'gifts') authority.giftIds = selectedGifts.value;
        else authority.giftIds = [];
        context.emit('input', authority);
      }
    );
    watch(
      () => selectedUsers.value,
      () => {
        authority.userIds = selectedUsers.value;
        context.emit('input', authority);
      }
    );
    watch(
      () => selectedPlans.value,
      () => {
        authority.planIds = selectedPlans.value;
        context.emit('input', authority);
      }
    );
    watch(
      () => selectedSeasons.value,
      () => {
        authority.seasonIds = selectedSeasons.value;
        context.emit('input', authority);
      }
    );
    watch(
      () => selectedProducts.value,
      () => {
        authority.productIds = selectedProducts.value;
        context.emit('input', authority);
      }
    );
    watch(
      () => selectedGifts.value,
      () => {
        authority.giftIds = selectedGifts.value;
        context.emit('input', authority);
      }
    );

    // disabled時のテキスト
    const getSelectedItemString = (type: string) => {
      const _getString = (selectedItems: string[], allItems: { text: string; value: string; isArchive: boolean }[]) => {
        const items = !allItems.length
          ? selectedItems
          : selectedItems.map((value) => {
              const _item = allItems.find((item) => item.value === value);
              return _item?.text ? (_item.isArchive ? `${_item?.text}(削除済み)` : _item?.text) : '';
            });
        return items.join('<br>');
      };

      if (type === 'plans') return _getString(selectedPlans.value, displayOptionPlans.value);
      if (type === 'gifts') return _getString(selectedGifts.value, displayOptionGifts.value);
      if (type === 'products') return _getString(selectedProducts.value, displayOptionProducts.value);
      if (type === 'seasons')
        return seasons.value.find((season) => season.value.toString() === selectedSeasons.value.toString())?.text || '';
    };

    return {
      selected,
      seasons,
      products,
      gifts,
      thisSeason,
      selectedPlans,
      selectedSeasons,
      selectedProducts,
      selectedGifts,
      selectedUsers,
      displayArchivePlan,
      displayArchiveProduct,
      displayArchiveGift,
      displayOptionPlans,
      displayOptionProducts,
      displayOptionGifts,
      getSelectedItemString,
    };
  },
});
