







import { defineComponent, ref, watch } from '@vue/composition-api';
import { download } from '@/admin/file';

export default defineComponent({
  props: {
    image: {
      type: String,
      required: true,
    },
  },
  setup(props) {
    const src = ref('');
    const downloading = ref(true);
    if (props.image) {
      download(props.image).then((signedUrl) => {
        src.value = signedUrl;
        downloading.value = false;
      });
    } else {
      downloading.value = false;
    }
    watch(
      () => props.image,
      () => {
        if (props.image) {
          downloading.value = true;
          download(props.image).then((signedUrl) => {
            src.value = signedUrl;
            downloading.value = false;
          });
        }
      }
    );

    return { src, downloading };
  },
});
