






























































































import { defineComponent, PropType, ref, watch } from '@vue/composition-api';
import { DisplayDate } from '@/admin/util';

export default defineComponent({
  props: {
    value: Object as PropType<DisplayDate>,
    isVisibleTime: {
      type: Boolean,
      default: true,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    enableSetCurrentTime: {
      type: Boolean,
      required: false,
      default: true,
    },
    selectionTimeOptions: {
      type: Array,
      required: false,
    },
    isSeconds: {
      type: Boolean,
      required: false,
      default: true,
    },
    // ダイアログ表示で使用する場合に入力日付をリセットできるようにする
    isReset: {
      type: Boolean,
      required: false,
    },
  },
  setup(props, context) {
    const date = ref(props.value?.format('YYYY-MM-DD'));
    const time = ref(props.value?.format(props.isSeconds ? 'hh:mm:ss' : 'hh:mm'));
    const dateRegex = /^[0-9]{4}-(0[1-9]|1[0-2])-(0[1-9]|[12][0-9]|3[01])$/;
    const isValidateDate = ref(true);
    const timeRegex = props.isSeconds
      ? /^([01][0-9]|2[0-3]):[0-5][0-9]:[0-5][0-9]$/
      : /^([01][0-9]|2[0-3]):[0-5][0-9]$/;
    const isValidateTime = ref(true);

    const clearDate = () => {
      date.value = '';
      time.value = '';
    };

    watch([date, time], () => {
      isValidateDate.value = date.value ? dateRegex.test(date.value) : false;
      isValidateTime.value = time.value ? timeRegex.test(time.value) : false;
      if (isValidateDate.value && isValidateTime.value) {
        context.emit('input', DisplayDate.parse(`${date.value} ${time.value}`));
      } else {
        context.emit('input', new DisplayDate());
      }
    });

    watch(
      () => [props.value, props.isVisibleTime],
      () => {
        if (props.value?.value) {
          date.value = new DisplayDate(props.value.value).format('YYYY-MM-DD');
          time.value = new DisplayDate(props.value.value).format(props.isSeconds ? 'hh:mm:ss' : 'hh:mm');
        }
        // 曜日が変更されたとき
        if (!props.isVisibleTime && date.value) {
          time.value = DisplayDate.setHours().format(props.isSeconds ? 'hh:mm:00' : 'hh:mm');
        }
      }
    );

    // ダイアログが閉じられた時に入力内容をリセットする
    watch(
      () => props.isReset,
      () => {
        if (!props.isReset) {
          clearDate();
        }
      }
    );

    const formatDate = (d: string) => {
      const [YYYY, MM] = d.split('-');
      return `${YYYY}年${Number(MM)}月`;
    };
    const formatWeekday = (d: string) => {
      const date = new Date(d);
      return ['日', '月', '火', '水', '木', '金', '土'][date.getDay()];
    };
    const timeOptions = [
      '00:00',
      '00:30',
      '01:00',
      '01:30',
      '02:00',
      '02:30',
      '03:00',
      '03:30',
      '04:00',
      '04:30',
      '05:00',
      '05:30',
      '06:00',
      '06:30',
      '07:00',
      '07:30',
      '08:00',
      '08:30',
      '09:00',
      '09:30',
      '10:00',
      '10:30',
      '11:00',
      '11:30',
      '12:00',
      '12:30',
      '13:00',
      '13:30',
      '14:00',
      '14:30',
      '15:00',
      '15:30',
      '16:00',
      '16:30',
      '17:00',
      '17:30',
      '18:00',
      '18:30',
      '19:00',
      '19:30',
      '20:00',
      '20:30',
      '21:00',
      '21:30',
      '22:00',
      '22:30',
      '23:00',
      '23:30',
    ].map((time) => (props.isSeconds ? `${time}:00` : time));

    const currentDate = () => {
      if (!props.isVisibleTime) {
        date.value = DisplayDate.setHours().format('YYYY-MM-DD');
        time.value = DisplayDate.setHours().format(props.isSeconds ? 'hh:mm:00' : 'hh:mm');
      } else {
        date.value = DisplayDate.now().format('YYYY-MM-DD');
        time.value = DisplayDate.now().format(props.isSeconds ? 'hh:mm:00' : 'hh:mm');
      }
    };

    return {
      date,
      time,
      formatDate,
      formatWeekday,
      timeOptions,
      currentDate,
      clearDate,
      isValidateDate,
      isValidateTime,
    };
  },
});
