



























































































































































import { defineComponent, ref, PropType, Ref, watch, computed } from '@vue/composition-api';
import core from '@/admin/core';
import { downloadCsv } from '@/admin/util';
import { useUser } from '@/admin/user';
import myAttributes from '@/composition/myAttributes';
import { useConfirm } from '@/composition/confirm';

interface DisplayUser {
  userId: string;
  name: string;
  auth: string;
  isFreePlanGroup: boolean;
}

export default defineComponent({
  name: 'UserSelect',
  props: {
    value: {
      type: Array as PropType<string[]>,
      require: true,
    },
  },
  setup(props, context) {
    // IP制限とロールの確認
    const isPermitted =
      myAttributes.myRequestPermissions?.users || myAttributes.myRequestPermissions?.usersWithoutPersonalInfos;
    const isPermittedWithNickname = myAttributes.myRequestPermissions?.users && core.isPermitted;
    const { getAllUsers } = useUser();
    const allUsers: Ref<DisplayUser[]> = ref([]);
    const isLoading = ref(true);
    const { confirmDialog } = useConfirm();

    getAllUsers()
      .then(
        (users) =>
          (allUsers.value = users
            .filter((user) => !user.deleteDate)
            .map((user) => {
              let auth = '';
              if (user.subscriptionNames.length) auth = user.subscriptionNames.join(', ');
              else if (user.seasonNames.length) auth = user.seasonNames.join(', ');
              else if (!user.isUncompletedSignup) auth = '無料プラン';
              return {
                userId: user.userId,
                name: isPermittedWithNickname
                  ? user.properties?.nickname || user.userNumber.toString().padStart(8, '0') || user.userId || '-'
                  : status !== 'invalid'
                  ? '権限により非表示'
                  : '-',
                auth,
                isFreePlanGroup:
                  !user.subscriptionPlanIds.length && !user.seasonNames.length && !user.isUncompletedSignup,
              };
            })),
        (e) => console.error(e)
      )
      .finally(() => (isLoading.value = false));
    const selectedUsers: Ref<string[]> = ref(props.value || []);

    // ユーザー指定
    const displaySelectedUsers = computed(() => {
      const users = selectedUsers.value.map((id) => {
        const user = allUsers.value.find((user) => id === user.userId);
        let status = 'unchecked';
        if (user) status = 'valid';
        else if (isPermitted) status = 'invalid'; // 権限がなくユーザーを取得できない場合：ステータスを変更しない

        return {
          id,
          status,
          name: user?.name,
          auth: user?.auth,
        };
      });
      return users;
    });
    const isActiveDialog = ref(false);
    const isCheckUsers = ref(false);
    const dialogTableHeaders = [
      { text: 'ユーザーID', value: 'id' },
      { text: 'ニックネーム', value: 'name' },
      { text: '権限', value: 'auth' },
      { text: '', value: 'actions', align: 'end' },
    ];
    const userId = ref('');
    const userCsv: Ref<Blob | null> = ref(null);
    const openDialog = () => {
      isActiveDialog.value = true;
    };
    const closeDialog = () => {
      userId.value = '';
      isActiveDialog.value = false;
    };
    const checkUserIds = async () => {
      userId.value = '';

      // ユーザーデータのチェックができない場合（IP制限）はそのままダイアログを閉じる
      if (!isPermitted) return (isActiveDialog.value = false);

      let hasInvalidUser = false;
      displaySelectedUsers.value.forEach((user) => {
        if (user.status === 'invalid' && !hasInvalidUser) hasInvalidUser = true;
      });

      // エラー（退会済みまたは存在しないユーザーID指定）があればアラートを表示する
      if (hasInvalidUser)
        alert(
          '登録対象外のユーザーが含まれています。\nアラートアイコンが表示されている行について、退会済みまたは存在しないユーザーIDを指定していないかご確認ください。'
        );
      else isActiveDialog.value = false;
    };
    const resetDialog = async () => {
      if (!(await confirmDialog('ユーザー登録をリセットしますか？'))) return;
      selectedUsers.value = [];
    };
    const setCsv = (file?: Blob) => {
      userCsv.value = file || null;
    };
    const uploadCsv = () => {
      if (!userCsv.value) return alert('ファイルを選択してください。');
      const reader = new FileReader();
      reader.readAsText(userCsv.value);
      reader.onload = () => {
        if (!reader.result) return;
        const csv = reader.result.toString().replace(/\r\n/g, '\n');
        const rows = csv.split('\n');

        // フォーマットの確認
        const newRows = rows.filter((row) => !!row);
        const header = newRows.shift();
        if (header !== 'userId' || !newRows.length || newRows.some((row) => row.includes(',')))
          return alert('正しくuserIdを指定してください。');

        // 重複・空白削除
        const ids = [...new Set(newRows.map((row) => row.trim()))];

        // 追加
        const _ids: string[] = [];
        ids.forEach((id) => {
          if (!selectedUsers.value.includes(id)) _ids.push(id);
        });
        selectedUsers.value.unshift(..._ids);
      };
    };
    const addUser = () => {
      if (!userId.value) return;
      if (selectedUsers.value.includes(userId.value)) {
        return alert('すでに登録されているユーザーです。');
      } else {
        selectedUsers.value.unshift(userId.value);
        userId.value = '';
      }
    };
    const addUserGroup = (type: string) => {
      let groupUserIds: string[] = [];
      if (type === 'freePlan') {
        groupUserIds = allUsers.value.filter((user) => user.isFreePlanGroup).map((user) => user.userId);
      }
      // 上記のif文で作成された追加するidsを選択値に追加
      const successIds: string[] = [];
      groupUserIds.forEach((id) => {
        if (!selectedUsers.value.includes(id)) successIds.push(id);
      });
      selectedUsers.value.unshift(...successIds);
      if (groupUserIds.length !== successIds.length)
        alert(`${groupUserIds.length - successIds.length}件重複により追加できませんでした`);
    };
    const removeUser = (id: string) => {
      selectedUsers.value = selectedUsers.value.filter((user) => user !== id);
    };
    const downloadCsvTemplate = () => {
      const csvHeaders = [{ text: 'userId', value: 'userId' }];
      downloadCsv(csvHeaders, [], 'users-list.csv');
    };

    watch(
      () => selectedUsers.value,
      () => {
        context.emit('input', selectedUsers.value);
      }
    );

    return {
      selectedUsers,
      isActiveDialog,
      isCheckUsers,
      userId,
      openDialog,
      closeDialog,
      checkUserIds,
      resetDialog,
      dialogTableHeaders,
      setCsv,
      uploadCsv,
      addUser,
      removeUser,
      downloadCsvTemplate,
      displaySelectedUsers,
      isLoading,
      addUserGroup,
    };
  },
});
